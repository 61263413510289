.VirtualSelectGrid {
  z-index: 1;
}

.VirtualizedSelectOption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 .5rem;
  cursor: pointer;
}
.VirtualizedSelectFocusedOption {
  background-color: rgba(0, 126, 255, 0.1);
}
.VirtualizedSelectDisabledOption {
  opacity: 0.5;
}
.VirtualizedSelectSelectedOption {
  font-weight: bold;
}
